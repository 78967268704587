import { v4 as uuidv4 } from "uuid";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/Alert";
import LoadingButton from "../../components/LoadingButton";
import IamgeSelector from "../../components/ImageSelector";
import ZoomableImage from "../../components/ZoomableImage/ZoomableImage";
import "./New.css";
import { AppContext } from "../../App";
import Resizer from "react-image-file-resizer";
import Confirm from "../../components/Confirm/Confirm";

const query = window.matchMedia("(orientation: portrait)");

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      800,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });

const New = () => {
  const navigate = useNavigate();
  const { appData, setAppData } = useContext(AppContext);
  const [photoCard, setPhotoCard] = useState(null);
  const zoomableImageData = useRef(null);

  const handleOrientationChanged = (e) => {
    navigate("/");
  };

  useEffect(() => {
    query.removeEventListener("change", handleOrientationChanged);
    query.addEventListener("change", handleOrientationChanged);

    return () => {
      query.removeEventListener("change", handleOrientationChanged);
    };
  }, []);

  useEffect(() => {
    if (!photoCard) return;
    navigate("/Card", { state: { photoCard } });
  }, [photoCard]);

  const getImageUploadUrl = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_API_SERVER}/v3/photo-cards/image-upload-url`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filename: `${uuidv4()}.jpg`,
        }),
      }
    );

    if (!res.ok) {
      console.error(res);
      throw new Error();
    }
    return await res.json();
  };

  const uploadImage = async (imageBlob, url) => {
    const res = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "image/jpeg",
      },
      body: imageBlob,
    });

    if (!res.ok) {
      throw new Error();
    }
  };

  const createPhotoCard = async (imageUrl) => {
    const expiresIn = new Date();
    expiresIn.setDate(expiresIn.getDate() + 6);
    const res = await fetch(
      `${process.env.REACT_APP_API_SERVER}/v3/photo-cards`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          imageUrls: [imageUrl],
          expiresIn: expiresIn,
        }),
      }
    );
    if (!res.ok) throw new Error();
    return await res.json();
  };

  if (appData.imgSrc) {
    return (
      <div className="New">
        <div className="zoomable-image-wrapper">
          <canvas id="canvas"></canvas>
          <Alert
            open={!!appData.error}
            message={appData.error ? appData.error.message : ""}
            onClose={() => {
              setAppData({
                ...appData,
                error: null,
              });
            }}
          />
          <ZoomableImage
            imgSrc={appData.imgSrc}
            onCloseButtonClick={() => {
              setAppData({
                ...appData,
                imgSrc: null,
              });
            }}
            onChange={(data) => {
              zoomableImageData.current = data;
            }}
          />
          <img
            className="rotate_90_clockwise_img"
            onClick={() => {
              // https://gist.github.com/Zyndoras/6897abdf53adbedf02564808aaab94db

              const canvas = document.getElementById("canvas");
              const ctx = canvas.getContext("2d");
              const image = new Image();

              image.onload = () => {
                canvas.height = image.width;
                canvas.width = image.height;

                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((90 * Math.PI) / 180);
                ctx.drawImage(image, image.width / -2, image.height / -2);

                setAppData({
                  ...appData,
                  imgSrc: canvas.toDataURL("image/jpeg", 100),
                });
                navigate("/");
              };
              image.src = appData.imgSrc;
            }}
            src={`${process.env.PUBLIC_URL}/assets/img/rotate_clockwise_90.svg`}
            alt="이미지 시계방향 90도 회전 아이콘"
          />
        </div>
        <LoadingButton
          isLoading={appData.isLoading}
          id="upload-image-button"
          text={"업로드"}
          onClick={() => {
            const canvas = document.getElementById("canvas");
            const ctx = canvas.getContext("2d");
            const originalImage = new Image();

            originalImage.addEventListener("load", async () => {
              setAppData({ ...appData, isLoading: true });
              //set the canvas size to the new width and height
              const { x, y, width, height } = zoomableImageData.current;
              canvas.width = width;
              canvas.height = height;

              //draw the image
              ctx.drawImage(
                originalImage,
                x,
                y,
                width,
                height,
                0,
                0,
                width,
                height
              );

              try {
                const { preSignedUrl, accessUrl } = await getImageUploadUrl();
                const blob = await new Promise((resolve) =>
                  canvas.toBlob(resolve, "image/jpeg")
                );
                const image = await resizeFile(blob);
                await uploadImage(image, preSignedUrl);
                const photoCard = await createPhotoCard(accessUrl);
                setAppData({
                  ...appData,
                  isLoading: false,
                  imgSrc: null,
                });
                setPhotoCard(photoCard);
              } catch (error) {
                console.error(error);
                setAppData({
                  ...appData,
                  isLoading: false,
                  error: new Error("포토 카드를 생성하는데 실패했습니다."),
                });
              }
            });

            originalImage.src = appData.imgSrc;
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="New">
        <div className="image-selector-wrapper">
          <IamgeSelector
            onChange={(e) => {
              setAppData({
                ...appData,
                imgSrc: URL.createObjectURL(e.target.files[0]),
              });
            }}
          />
          <div className="image-selector-description">
            <span>이미지를 불러오세요.</span>
            <br />
            <span>사진을 편집하고 업로드해 주세요.</span>
          </div>
        </div>
      </div>
    );
  }
};

export default New;
