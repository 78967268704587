import { useRef } from "react";

const IamgeSelector = ({ onChange }) => {
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <div className="ImageSelector" onClick={handleClick}>
      <input ref={inputRef} type="file" accept="image/*" onChange={onChange} />
      <div>+</div>
    </div>
  );
};

export default IamgeSelector;
