import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";

const LoadingButton = ({ id, isLoading, text, onClick }) => {
  const Styled = styled(MuiLoadingButton)(({ theme }) => ({
    border: "none",
    borderRadius: 0,
    whiteSpace: "nowrap",
    fontSize: "16px",
    fontFamily: "Noto Sans KR",
    fontWeight: "bold",
    backgroundColor: "#ff9500",
    color: "white",
  }));

  return (
    <Styled id={id} loading={isLoading} onClick={onClick}>
      {text}
    </Styled>
  );
};

export default LoadingButton;
