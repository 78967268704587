import $ from "jquery";
import { useEffect } from "react";
import "./uber-zoom.css";

const ZoomableImage = ({ imgSrc, onCloseButtonClick, onChange }) => {
  const uberZoomOption = {
    maxZoom: 4,
    callback: ({
      frameWidth,
      frameHeight,
      contentWidth,
      contentHeight,
      currentZoom,
      img,
      currentPosX,
      currentPosY,
    }) => {
      // - content(width, height)는 프레임 크기가 아니다. 실제 이미지 크기도 아니다. img 태그에 이미지가 로드될 때의 이미지 크기다. 작은 사이즈의 이미지라면 content는 실제 이미지 크기보다 클 것이고, 큰 사이즈 이미지라면 content는 실제 이미지 크기보다 작을 것이다. 가로 세로 비율은 실제 이미지의 가로 세로 비율과 같다.
      // - current(width, height)는 줌이 적용된 크기이다.
      const currentWidth = contentWidth * currentZoom;
      const currentHeight = contentHeight * currentZoom;
      const x = (img.width * Math.abs(currentPosX)) / currentWidth;
      const y = (img.height * Math.abs(currentPosY)) / currentHeight;
      const width = (img.width * frameWidth) / currentWidth;
      const height = (img.height * frameHeight) / currentHeight;

      onChange({
        x,
        y,
        width,
        height,
      });
    },
    showUICallback: () => {
      document.getElementById("close-button").style["opacity"] = 0;
      document.getElementById("grid").style["opacity"] = 1;
    },
    hideUICallback: () => {
      document.getElementById("close-button").style["opacity"] = 1;
      document.getElementById("grid").style["opacity"] = 0;
    },
  };

  useEffect(() => {
    if ($(".ndd-uberzoom-container").length) return;
    $(".ZoomableImage img").uberZoom(uberZoomOption);
  }, []);

  return (
    <div className="ZoomableImage">
      <div
        id="close-button"
        className="close-button"
        onClick={() => {
          onCloseButtonClick();
        }}
      >
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <img alt="원본 이미지" src={imgSrc} />
      <div id="grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default ZoomableImage;
