import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import "./Card.css";

const Card = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { photoCard } = state;
  const { authCode, imageUrls, expiresIn } = photoCard;
  // console.log(expiresIn);
  const expiresInDate = new Date(expiresIn);
  // console.log(expiresInDate);
  if (photoCard) {
    return (
      <div className="Card">
        <div className="photo-card-image-wrapper">
          <img src={imageUrls[0]} alt="포토 카드 이미지" />
        </div>
        <div className="auth-code-text">
          <span className="auth-code-number-title">출력 인증번호</span>
          <div className="auth-code-number">
            {authCode.split("").map((val, idx) => (
              <span key={idx}>{val}</span>
            ))}
          </div>
          <span className="auth-code-desc1">
            인증번호는 포토 조회에 꼭 필요합니다.
          </span>
          <span className="auth-code-desc2">캡쳐후 보관해주세요.</span>
          <span className="auth-code-expires-in">
            출력 가능 기간 : {expiresInDate.getFullYear()}.
            {expiresInDate.getMonth() + 1}.{expiresInDate.getDate()}
          </span>
        </div>
        <Button
          className="confirm-button"
          text={"확인"}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Card;
